<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Merchant Area</span>
      <el-button class="btn-add" @click="handleAdd()" size="mini">
        Add Area
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table ref="table" style="width: 100%" :data="list" v-loading="listLoading" border>
        <el-table-column label="Name" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="Image">
          <template slot-scope="scope"><img :src="scope.row.imageUrl" style="width: 300px" /></template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleUpdate(scope.row)">编辑
            </el-button>
            <!-- <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="param" label-width="120px">
        <el-form-item label="Name" prop="name">
          <el-input v-model="param.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Image" prop="imageUrl">
          <single-upload v-model="param.imageUrl">
          </single-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchMerchantArea,
  createMerchantArea,
} from '@/api/merchantArea';
import SingleUpload from '@/components/Upload/singleUpload';

export default {
  name: 'merchantArea',
  components: { SingleUpload },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      dialogVisible: false,
      dialogTitle: '',
      updateId: null,
      param: {
        id: null,
        name: null,
        imageUrl: null,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleAdd() {
      this.dialogTitle = 'Add Merchant Area';
      this.dialogVisible = true;
      this.param.name = null;
      this.param.id = null;
    },
    handleUpdate(param) {
      this.dialogTitle = 'Edit Merchant Area';
      this.dialogVisible = true;
      this.param = param;
    },
    getList() {
      this.listLoading = true;
      fetchMerchantArea().then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    handleConfirm() {
      createMerchantArea(this.param).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.dialogVisible = false;
        this.getList();
      });
    },
  },
  filters: {},
};
</script>

<style scoped>
</style>
