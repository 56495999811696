import request from '@/utils/request';

export function fetchMerchantArea() {
  return request({
    url: 'v1/merchants/area/list',
    method: 'get',
  });
}

export function createMerchantArea(data) {
  return request({
    url: 'v1/merchants/area/create',
    method: 'post',
    data,
  });
}
